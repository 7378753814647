export default [
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/requests',
    name: 'requests-list',
    component: () => import('@/views/modules/request/requests-list/RequestsList.vue'),
    meta: {
      pageTitle: 'signup_requests',
      action: 'manage',
      resource: 'user',
    },
  },
  {
    path: '/requests/:id',
    name: 'requests-view',
    component: () => import('@/views/modules/request/requests-view/RequestsView.vue'),
    meta: {
      pageTitle: 'signup_requests',
      action: 'manage',
      resource: 'user',
    },
  },
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/modules/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'account_settings',
      action: 'update',
      resource: 'user',
    },
  },
];
